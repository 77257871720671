//load userBNBBalance
export const loadUserBnbBalance = async (library, userAddress) => {
    const result = await library.eth.getBalance(userAddress);
    return library.utils.fromWei(result, 'ether');
};

//load userUsdBalance
export const loadUserUsdBalance = async (library, busdContract, userAddress) => {
    const result = await busdContract.methods.balanceOf(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};

//load userMbpBalance
export const loadUserMbpBalance = async (library, mbpContract, userAddress) => {
    const result = await mbpContract.methods.balanceOf(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};

//load userDksBalance
export const loadUserDksBalance = async (library, dksContract, userAddress) => {
    const result = await dksContract.methods.balanceOf(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};
///////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////
//load sale enabled
export const loadSaleEnabled = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getSaleEnabled(idoContractAddr).call();
    return result;
};

//load claim enabled
export const loadClaimEnabled = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getClaimEnabled(idoContractAddr).call();
    return result;
};

//load refund enabled
export const loadRefundEnabled = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getRefundEnabled(idoContractAddr).call();
    return result;
};

//load userBalance
export const loadUserBalance = async (library, routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.userInvestment(idoContractAddr, userAddress).call();
    return library.utils.fromWei(result, 'ether');
};

//load userAmountClaimed
export const loadUserAmountClaimed = async (library, routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.userAmountClaimed(idoContractAddr, userAddress).call();
    return library.utils.fromWei(result, 'ether');
};

//load Total Invested
export const loadTotalInvested = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalInvested(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load Targeted Raise
export const loadTargetedRaise = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getHardCap(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load Token Per USD
export const loadTokensPerUsd = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTokensPerUSD(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load Referral BNB Amount
export const loadRefUsdAmount = async (library, routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getUserReferralReward(idoContractAddr, userAddress).call();
    return library.utils.fromWei(result, 'ether')
};

//load User total referral
export const loadUserRefCount = async (routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getUserReferralCount(idoContractAddr, userAddress).call();
    return result;
};

//load referral percentage
export const loadRefPercentage = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getReferralPercentage(idoContractAddr).call();
    return result;
};

//load referral claimed
export const loadRefClaimed = async (routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getRefRewardClaimed(idoContractAddr, userAddress).call();
    return result;
};

//load Total Ref USD Amount
export const loadTotalRefUsdAmount = async (library, routerContract, idoContractAddr) => {
    const balance = await routerContract.methods.getTotalReferralReward(idoContractAddr).call();
    return library.utils.fromWei(balance, 'ether')
};

//load Minimum MBP to have
export const loadMinMbpHolding = async (library, routerContract, idoContractAddr) => {
    const balance = await routerContract.methods.getMinimumMBPHolding(idoContractAddr).call();
    return library.utils.fromWei(balance, 'ether')
};

//load Minimum DKS to have
export const loadMinDksHolding = async (library, routerContract, idoContractAddr) => {
    if (idoContractAddr === "0xCcA29632dd71df829995DdaA709e61C22Fd5A0Cc"
        ||
        idoContractAddr === "0x356F7e0365226ba312E6D71a2aA4Ca1Ed9B7e105") {
        return 0;
    } else {
        const balance = await routerContract.methods.getMinimumDKSHolding(idoContractAddr).call();
        return library.utils.fromWei(balance, 'ether')
    }
};

//load Max buy per user
export const loadMaxBuyPerUser = async (library, routerContract, idoContractAddr) => {
    const balance = await routerContract.methods.getMaximumUsdBuyAmount(idoContractAddr).call();
    return library.utils.fromWei(balance, 'ether')
};

//load Min buy per user
export const loadMinBuyPerUser = async (library, routerContract, idoContractAddr) => {
    const balance = await routerContract.methods.getMinimumInvestment(idoContractAddr).call();
    return library.utils.fromWei(balance, 'ether')
};

//load USD Allowance //Also used for other tokens
export const loadUsdAllowance = async (library, usdContract, userAddress, idoContractAddr) => {
    const balance = await usdContract.methods.allowance(userAddress, idoContractAddr).call();
    return library.utils.fromWei(balance, 'ether')
};

//load the active vesting
export const loadActiveVesting = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getActiveVesting(idoContractAddr).call();
    return result;
};


export const loadUserMBPAmountStaked = async (library, stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserMBPAmountStaked(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};
export const loadUserDKSAmountStaked = async (library, stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserDKSAmountStaked(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};
export const loadUserMBP_StakeDaysCount = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.MBP_StakeDaysCount(userAddress).call();
    return result;
};
export const loadUserDKS_StakeDaysCount = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.DKS_StakeDaysCount(userAddress).call();
    return result;
};
export const loadMinDaysToUnstake = async (stakingContract) => {
    const result = await stakingContract.methods.getMinimumDaysToUnStake().call();
    return result;
};
export const loadMinMBPStake = async (library, stakingContract) => {
    const result = await stakingContract.methods.getMinimum_MBP_Stake().call();
    return library.utils.fromWei(result, 'ether');
};
export const loadMinDKSStake = async (library, stakingContract) => {
    const result = await stakingContract.methods.getMinimum_DKS_Stake().call();
    return library.utils.fromWei(result, 'ether');
};
///////////////////////////////////////////////////////////////



//Approve USD //Also used for other tokens
export const approveUsdSpending = async (
    library, usdContract, usdContractAddress, idoContractAddr, address, investAmount
) => {

    //set up transaction parameters
    const transactionParameters = {
        to: usdContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: usdContract.methods.approve(idoContractAddr, library.utils.toWei(String(investAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Approve Successful."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

export const stakeMBPToken = async (library, stakingContractAddress, stakingContract, address, stakeAmount) => {

    if (stakeAmount.toString().trim() === "" || stakeAmount <= 0) {
        return {
            status: "Please Input Stake Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(stakeAmount, 'ether')),
        data: stakingContract.methods.stakeMBP(library.utils.toWei(String(stakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully staked MBP tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

export const stakeDKSToken = async (library, stakingContractAddress, stakingContract, address, stakeAmount) => {

    if (stakeAmount.toString().trim() === "" || stakeAmount <= 0) {
        return {
            status: "Please Input Stake Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(stakeAmount, 'ether')),
        data: stakingContract.methods.stakeDKS(library.utils.toWei(String(stakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully staked DKS tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

export const unstakeMBPToken = async (library, stakingContractAddress, stakingContract, address, unstakeAmount) => {

    if (unstakeAmount.toString().trim() === "" || unstakeAmount <= 0) {
        return {
            status: "Please Input Unstake Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(unstakeAmount, 'ether')),
        data: stakingContract.methods.unstakeMBP(library.utils.toWei(String(unstakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully unstaked MBP tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

export const unstakeDKSToken = async (library, stakingContractAddress, stakingContract, address, unstakeAmount) => {

    if (unstakeAmount.toString().trim() === "" || unstakeAmount <= 0) {
        return {
            status: "Please Input Unstake Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(unstakeAmount, 'ether')),
        data: stakingContract.methods.unstakeDKS(library.utils.toWei(String(unstakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully unstaked DKS tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Normal buyToken
export const buyToken = async (library, routerContractAddress, routerContract, idoContractAddr, address, investAmount) => {

    if (investAmount.toString().trim() === "" || investAmount <= 0) {
        return {
            status: "Please Input USDT Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(investAmount, 'ether')),
        data: routerContract.methods.buyToken(idoContractAddr, library.utils.toWei(String(investAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Thanks for investing in the IDO. You can claim your tokens after the sale."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Referral buyToken
export const buyTokenRef = async (library, routerContractAddress, routerContract, idoContractAddr, address, referrer, investAmount) => {

    if (investAmount.toString().trim() === "" || investAmount <= 0) {
        return {
            status: "Please Input BNB Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(investAmount, 'ether')),
        data: routerContract.methods.buyTokenReferral(idoContractAddr, referrer, library.utils.toWei(String(investAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Thanks for investing in the IDO. You can claim your tokens after the sale."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Claim Token
export const claimToken = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.claimToken(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Claim Successful. Once the transaction is verified by the network, you will get your tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Claim Referral Reward
export const claimRefReward = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.claimReferralUsd(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Claim Successful. Once the transaction is verified by the network, you will get your USDT."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Refund
export const refundUsd = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.refund(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Refund Successful. Once the transaction is verified by the network, you will get your USDT."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};
